<template>
    <v-dialog persistent
        transition="dialog-top-transition"
        v-model="open"
        max-width="400"
        @click:outside="$emit('close')"
    >
      <v-card class="pa-0 pa-3">
        <div class="d-flex align-center justify-center">
            <v-icon color="info" size="100">mdi-information-outline</v-icon>
        </div>
        <v-card-title class="flex-column justify-center pa-0 pa-3 pt-0 text-capitalize">
            <div class="text-subtitle-2 text-center">Are you sure want to update selected contact <br>information:</div>
        </v-card-title>

        <v-card-actions class="align-center justify-center">
          <v-btn color="primary" small @click="$emit('proceed')">
            Yes, Proceed
          </v-btn>

          <v-btn class="white--text" color="danger" small @click="$emit('close')">
            Restart
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['open'],
    emits: ['close', 'proceed'],
    data: (vm) => ({
        actionLoading: false
    })
}
</script>
